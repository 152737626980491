import React, { useState, createContext } from 'react';

const AuthContext = createContext({
    token: '',
    bool: '',
    type:'',
    subID: '',
    editID:'',
    isDelete: false,
    isLoggedIn: false,
    login: (token, email, username) => {},
    logout: () => {},
    addTask: (bool, type, subID) => {},
    saveTask: () => {},
    isDeleteHandler: (bool) => {},
    editTask: (bool, type, id, title, desc, duedate, priority) => {},
    closePopup: () => {},
    SearchViewBool:'',
    expandInput:(bool)=>{},
    access: (access)=> {}

});
console.log(AuthContext);
export const AuthContextProvider = (props) => {
   const [token, setToken] = useState(null);
   const [bool, setbool] =  useState(null);
   const [SearchViewBool, setSearchViewBool] = useState(false);
   const [type, setType] = useState(null);
   const [editData, setEditData] = useState([]);
   const [isdelete, setIsDelete] = useState(null);
   const [email, setEmail] = useState();
   const [username, setUsername] = useState();
   const [accessLevel, setAccessLevel] = useState([]);
   const [subID, setSubID] = useState();
   

   const userIsLoggedIn = !!token;
   const isDataDeleted = !!isdelete;
   const addTaskpopupBehav = !!bool;
   const isSearchView = !!SearchViewBool;
//    const getEditID = !!editID;
    let editTaskpopupBehav = '';
   if(type === 'add'){
    editTaskpopupBehav = 'add'
   } else if(type === 'edit'){
    editTaskpopupBehav = 'edit'
   } else if(type === 'addSub'){
    editTaskpopupBehav = 'addSub'
   }

   console.log(editTaskpopupBehav);
   
   const loginHandler = (token, email, username) => {
    setToken(token);
    setEmail(email);
    setUsername(username);

  
   }
   const getAccessHandler = (access) => {
    // console.log(access, 'authCOnext')
    setAccessLevel(access);
   }
//    console.log(token);
   const logoutHandler = () => {
    setToken(null);
   }
   const addTaskHandler = (bool, type, subID) => {
    setbool(bool);
    setType(type);
    subID && setSubID(subID);
    setEditData({
        'id': '',
        'Title': '',
        'Description': '',
        'DueDate': '',
        "priority": ''
    })  
}
 
const handleClose = (bool) => {
    setbool(bool);
};
const saveTaskHandler = () => {

}

const deleteHandlerChange = (bool) => {
// alert("delete notify in contextAPi")
setIsDelete(bool)
}
const editTaskHandler = (bool, type, id, title, desc, duedate, priority) => {
    setbool(bool);
    setType(type)
    setEditData({
        'id': id,
        'Title': title,
        'Description': desc,
        'DueDate': duedate,
        "priority": priority
    })
}
const expandInput = (bool) => {
    setSearchViewBool(bool);
}
 const AuthContextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    ispopupOpen: addTaskpopupBehav,
    typeIs: editTaskpopupBehav,
    subID: subID,
    editData: editData,
    SearchViewBool:SearchViewBool,
    login: loginHandler,
    logout: logoutHandler,
    addTask: addTaskHandler,
    saveTask: saveTaskHandler,
    editTask: editTaskHandler,
    closePopup: handleClose,
    delete: isDataDeleted,
    isDeleteHandler: deleteHandlerChange,
    email: email,
    username: username,
    searchViewPopupStatus: expandInput,
    access: getAccessHandler,
    checkAcess: accessLevel
   }

    //  console.log(accessLevel);
    return (
        <AuthContext.Provider value={AuthContextValue}>{props.children}</AuthContext.Provider>
    );
};
 export default AuthContext
