import React, { useEffect, useState, useContext, useRef } from "react";
import Header_dashboard from "./Header/Header_dashboard";
import Header_sidebar from "./Header/Header_sidebar";
import Box from "@mui/material/Box"; 
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Checkbox } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import AuthContext from '../auth/Auth-context';
import AddTask from '../dashboard/AddTask';

const Today = () => {
  const [tasks, setTasks] = useState([]);
  const authCtx = useContext(AuthContext);
  const [idsDel, setIdsDel] = useState([]);
  




  useEffect(() => {
    const email = localStorage.getItem("email");
    fetchUserByEmail(email);
  }, [authCtx])

 const fetchUserByEmail = async(email) => {
  try {
  const getUserByEmail = await axios.get(`https://server.taskticks.com/api/users/email/${email}`);
  //  console.log(getUserByEmail.data);
  if(getUserByEmail.data.length > 0){
    setTasks(getUserByEmail.data);
  }
  
  //  console.log(tasks)
  
  } catch (err) {
    console.log(err);
  }
  
 }

  const addTaskHandler = (bool, type, subID) => {
    // setOpen(true);
    subID = subID ? subID : null;
    console.log(bool, type, subID);
    authCtx.addTask(bool, type, subID);
   
  }

  const editHander = (id, title, desc, duedate, priority, type, bool) => {   
     console.log(id, title, desc, duedate, priority, type);
    authCtx.editTask(bool, type, id, title, desc, duedate, priority);  
  
  }
  
  const deleteHandlerChange = async(id, title, descript, DueDate, emailID) => {
   
    console.log(id, title, descript, DueDate, emailID);
    const checkbox = document.getElementById(id);
    if(checkbox.checked) {
 
    var previousJsonArray = localStorage.getItem('completedtask');
    var previousArray = JSON.parse(previousJsonArray) || [];
    var CurrentdeleteArray =  [{
      "Id": id,
      "taskName": title,
      "description": descript,
      "dueDate": DueDate,
      "CompletedOn": new Date(),
      "email" : emailID
    }]
    
    var mergedArray = previousArray.concat(CurrentdeleteArray);
    var mergedJsonArray = JSON.stringify(mergedArray);
    localStorage.setItem('completedtask', mergedJsonArray);
 
        try {
            const collectAllIdRef = tasks.filter(item => id === item.subtaskId);
            //  console.log(collectAllIdRef);
            if(collectAllIdRef.length > 0) {
              collectAllIdRef.map(item => {
                //  console.log(item._id);
                setTimeout( async() => {
                  const deleteUser = await axios.delete(`https://server.taskticks.com/api/users/${item._id}`);
                  if(deleteUser){
                    console.log("Sub tasks deleted successfully");
                  }
                }, 2000)
              })
            }
            
          const deleteUser = await axios.delete(`https://server.taskticks.com/api/users/${id}`);
          if(deleteUser){
            console.log("deleted successfully");
            const email = localStorage.getItem("email");
            fetchUserByEmail(email);
          }
         } catch (error) {
          
         }
   
       
      
      
   
   
 
      authCtx.isDeleteHandler(true);
    } else {
       
    }
  }





  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Header_dashboard />
        <Header_sidebar />

        <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f2edf8" }}>
          <Toolbar />
          <h4>
            <b>Today</b>
          </h4>
          <div className="card p-3 background-white">
            <div className="row">
              <div className="col-md-10">
                <div className="d-flex">
                  <span
                    className="p-2 mt-3 me-2 mb-3"
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      fontSize: "12px",
                      lineHeight: 1,
                      borderRadius: "10px",
                    }}
                  >
                    Personal
                  </span>
                  <span
                    className="p-2 mt-3 mb-3"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: "12px",
                      lineHeight: 1,
                      borderRadius: "10px",
                    }}
                  >
                    {tasks.length} Task
                  </span>
                </div>
              </div>
              <div className="col-md-2 text-end">
                <MoreVertIcon className="mt-3 me-2 mb-3" />
              </div>
            </div>
            <Divider />
            {tasks.length > 0 ? tasks.map((task, index) => {
            const date = task.dueDate ?  new Date(task.dueDate) : "No due date";
            //  console.log(new Date(task.dueDate))
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const humanReadableDate = date.toLocaleDateString('en-US', options);
         
            
                if(!task.subtaskId){
                  //  console.log(task._id)
                  // setIdsDel((prevIds) => [...prevIds, task._id]);
                  

                  // console.log(idsDel);
                  
                  return (
                    <div key={index} className="d-flex align-items-start mt-2 borderBottom editDiv">
                      <Checkbox id={task._id} className="me-2 " onClick={() => {deleteHandlerChange(task._id, task.taskName, task.description, humanReadableDate, task.email)}}/>
                      <div className="taskSection">
                          
                          <h3 title="Check the daily mails" alt="Check the daily mails" onClick={()=> {editHander(task._id, task.taskName, task.description, humanReadableDate, task.priority,'edit', true)}}>
                          {task.taskName}
                        </h3>
                        <div className="descriptionDiv" onClick={()=> {editHander(task._id, task.taskName, task.description, humanReadableDate, task.priority,'edit', true)}} > <span dangerouslySetInnerHTML={{ __html: task.description }}></span></div>
                        <p>Due on: {humanReadableDate}</p>
                        
                        <div className="subtask">
                       {tasks.map((subtask, index) => {
                         if(subtask.subtaskId === task._id) {
                           //  console.log(subtask)
                           const datesubtask = subtask.dueDate ?  new Date(subtask.dueDate) : "No due date";
                                //  console.log(new Date(subtask.dueDate))
                                const options1 = { year: 'numeric', month: 'long', day: 'numeric' };
                                const humanReadableDate1 = datesubtask.toLocaleDateString('en-US', options1);
                           return (
                            <div className="d-flex align-items-start mt-2">
                         <Checkbox id={subtask._id} className="me-2 " onClick={() => {deleteHandlerChange(subtask._id, subtask.taskName, subtask.description, humanReadableDate, subtask.email)}}/>
                         <div className="taskSection" onClick={()=> {editHander(subtask._id, subtask.taskName, subtask.description, humanReadableDate1, subtask.priority,'edit', true)}}>
                           <h3
                             title="Check the daily mails"
                             alt="Check the daily mails"
                           >
                             {subtask.taskName}
                           </h3>
                           <h5>
                           {subtask.description}
                           </h5>
                           <p>{humanReadableDate1}</p>
                         </div>
                       </div>
     
                          );
                         }
                         
                       })}
                       {!task.subtaskId && <Button
                        variant="text"
                        sx={{ textTransform: "inherit", color: "#888" }}
                        onClick={() => {addTaskHandler(true, 'addSub', task._id)}}
                      >
                        <AddOutlinedIcon />
                        ADD SUBTASK
                      </Button>}
                      
                    </div>
                      </div>
                      <BorderColorIcon className="mt-3 me-2 mb-3 editButton" />
    
                      
                    </div>
                  )
                }
              
            }): 'No tasks found'}
             
             
            
            <Button onClick={() => {addTaskHandler(true, 'add')}}  className="mb-3 mt-1" variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important', width: '150px'}}><AddOutlinedIcon/> ADD TASK</Button>
          </div>
          
        </Box>
      </Box>
    </div>
  );
};
export default Today;