import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CardContent from "@mui/material/CardContent";
import "./Dashboard.css";
import Header_dashboard from "./Header/Header_dashboard";
import Header_sidebar from "./Header/Header_sidebar";
import { Card } from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(8)} - 1px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Main = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Header_dashboard />
        <Header_sidebar />

        <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f2edf8" }}>
          <DrawerHeader />

          <div className="DashboardBody">
            <h4>
              <b>Dashboard</b>
            </h4>

            <div className="row">
              <div className="col-md-6">
                <div className="card cardRes mt-2 mb-2 ps-4 pe-1">
                  <div
                    className="row pt-2 pb-2"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-8">
                      <div className="" style={{ textAlign: "left" }}>
                        <img
                          src="/images/icons/Group 529.png"
                          style={{ marginRight: "10px", width: "40px" }}
                        />{" "}
                        <b style={{ fontSize: "20px" }}>Total Task</b>
                      </div>
                    </div>
                    <div className="col-md-4 text-end">
                      <p className="totalCount">
                        22
                        <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card cardRes mt-2 mb-2 ps-4 pe-1">
                  <div
                    className="row pt-2 pb-2"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-6">
                      <div className="" style={{ textAlign: "left" }}>
                        <img
                          src="/images/icons/Layer 13.png"
                          style={{ marginRight: "10px", width: "40px" }}
                        />{" "}
                        <b style={{ fontSize: "20px" }}>Today Task</b>
                      </div>
                    </div>
                    <div className="col-md-6 text-end">
                      <p className="totalCount">
                        5 <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card cardRes mt-2 mb-2 ps-4 pe-1">
                  <div
                    className="row pt-2 pb-2"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-6">
                      <div className="" style={{ textAlign: "left" }}>
                        <img
                          src="/images/icons/Group 531.png"
                          style={{ marginRight: "10px", width: "40px" }}
                        />{" "}
                        <b style={{ fontSize: "20px" }}>Upcoming Task</b>
                      </div>
                    </div>
                    <div className="col-md-6 text-end">
                      <p className="totalCount">
                        12{" "}
                        <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card cardRes mt-2 mb-2 ps-4 pe-1">
                  <div
                    className="row pt-2 pb-2"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-6">
                      <div className="" style={{ textAlign: "left" }}>
                        <img
                          src="/images/icons/Group 530.png"
                          style={{ marginRight: "10px", width: "40px" }}
                        />{" "}
                        <b style={{ fontSize: "20px" }}>Pending Task</b>
                      </div>
                    </div>
                    <div className="col-md-6 text-end">
                      <p className="totalCount">
                        45{" "}
                        <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card cardRes mt-2 mb-2 ps-4 pe-1">
                  <div
                    className="row pt-2 pb-2"
                    style={{ alignItems: "center" }}
                  >
                    <div className="col-md-6">
                      <div className="" style={{ textAlign: "left" }}>
                        <img
                          src="/images/icons/Layer 12.png"
                          style={{ marginRight: "10px", width: "40px" }}
                        />{" "}
                        <b style={{ fontSize: "20px" }}>Complete Task</b>
                      </div>
                    </div>
                    <div className="col-md-6 text-end">
                      <p className="totalCount">
                        10{" "}
                        <KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="onWrKingTask">
              <h4>
                <b>Onworking Task</b>
              </h4>
            </div>

            <div className="row mt-4">
              <div className="col-md-3">
                <Card className="cardRes1">
                  <CardContent >
                    <span className="leftLabel">Personal</span>
                    <span style={{ float: "right" }}>
                      <MoreVertOutlinedIcon />
                    </span>

                    <div className="heading">
                      <h4>My Today Work</h4>
                      <h5>22/02/2024</h5>
                    </div>
                    <div
                      className="progressBarGrey"
                      style={{
                        width: "100%",
                        height: "10px",
                        background: "#D9D9D9",
                        borderRadius: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="activeProgressBar"
                        style={{
                          position: "absolute",
                          height: "10px",
                          borderRadius: "10px",
                           
                          background: "linear-gradient(90deg, #9706C1 -3.64%, #8E4BCC -3.63%, rgba(136, 47, 219, 0.57) 100%)",

                          width: "70%",
                        }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between">
                    <small>Progress</small>
                    <small style={{textAlign: ''}}>70%</small>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                    <small><AddOutlinedIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    <small><KeyboardArrowRightIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className="cardRes1">
                  <CardContent >
                    <span className="leftLabel">Team</span>
                    <span style={{ float: "right" }}>
                      <MoreVertOutlinedIcon />
                    </span>

                    <div className="heading">
                      <h4>AI Project</h4>
                      <h5>22/02/2024</h5>
                    </div>
                    <div
                      className="progressBarGrey"
                      style={{
                        width: "100%",
                        height: "10px",
                        background: "#D9D9D9",
                        borderRadius: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="activeProgressBar"
                        style={{
                          position: "absolute",
                          height: "10px",
                          borderRadius: "10px",
                           
                          background: "linear-gradient(90deg, #9706C1 -3.64%, #8E4BCC -3.63%, rgba(136, 47, 219, 0.57) 100%)",

                          width: "50%",
                        }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between">
                    <small>Progress</small>
                    <small style={{textAlign: ''}}>50%</small>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                    <small><AddOutlinedIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    <small><KeyboardArrowRightIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-3">
                <Card className="cardRes1">
                  <CardContent >
                    <span className="leftLabel">Personal</span>
                    <span style={{ float: "right" }}>
                      <MoreVertOutlinedIcon />
                    </span>

                    <div className="heading">
                      <h4>My Today Work</h4>
                      <h5>22/02/2024</h5>
                    </div>
                    <div
                      className="progressBarGrey"
                      style={{
                        width: "100%",
                        height: "10px",
                        background: "#D9D9D9",
                        borderRadius: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="activeProgressBar"
                        style={{
                          position: "absolute",
                          height: "10px",
                          borderRadius: "10px",
                           
                          background: "linear-gradient(90deg, #9706C1 -3.64%, #8E4BCC -3.63%, rgba(136, 47, 219, 0.57) 100%)",

                          width: "70%",
                        }}
                      ></div>
                    </div>
                    <div className="d-flex justify-content-between">
                    <small>Progress</small>
                    <small style={{textAlign: ''}}>70%</small>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                    <small><AddOutlinedIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    <small><KeyboardArrowRightIcon  sx={{background: '#FF00A1', color: 'white', borderRadius: '50%', fontSize: '30px'}}/></small>
                    </div>
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Main;
