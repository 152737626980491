import React from "react";
import Header_dashboard from "./Header/Header_dashboard";
import Header_sidebar from "./Header/Header_sidebar";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Checkbox } from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const Complete = () => {
    return (
        <div>
        <Box sx={{ display: "flex" }}>
          <Header_dashboard />
          <Header_sidebar />
  
          <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f2edf8" }}>
            <Toolbar />
            <h4>
              <b>Complete</b>
            </h4>
            <div className="card p-3 background-white">
              <div className="row">
                <div className="col-md-10">
                  <div className="d-flex">
                    <span
                      className="p-2 mt-3 me-2 mb-3"
                      style={{
                        backgroundColor: "blue",
                        color: "white",
                        fontSize: "12px",
                        lineHeight: 1,
                        borderRadius: "10px",
                      }}
                    >
                      Personal
                    </span>
                    <span
                      className="p-2 mt-3 mb-3"
                      style={{
                        backgroundColor: "green",
                        color: "white",
                        fontSize: "12px",
                        lineHeight: 1,
                        borderRadius: "10px",
                      }}
                    >
                      5 Task
                    </span>
                  </div>
                </div>
                <div className="col-md-2 text-end">
                  <MoreVertIcon className="mt-3 me-2 mb-3" />
                </div>
              </div>
              <Divider />
              <div className="d-flex align-items-start mt-2 borderBottom editDiv">
                <Checkbox className="me-2 " />
                <div className="taskSection">
                  <h3 title="Check the daily mails" alt="Check the daily mails">
                    Check the daily mails
                  </h3>
                  <h5>Set reminders for time-sensitive emails and tasks. </h5>
                  <p>09:00 AM</p>
                  <div className="subtask">
                    <div className="d-flex align-items-start mt-2">
                      <Checkbox className="me-2 " />
                      <div className="taskSection">
                        <h3
                          title="Check the daily mails"
                          alt="Check the daily mails"
                        >
                          Check the daily mails
                        </h3>
                        <h5>
                          Set reminders for time-sensitive emails and tasks.{" "}
                        </h5>
                        <p>09:00 AM</p>
                      </div>
                    </div>
                    <Button
                      variant="text"
                      sx={{ textTransform: "inherit", color: "#888" }}
                    >
                      <AddOutlinedIcon />
                      ADD SUBTASK
                    </Button>
                  </div>
                </div>
                <BorderColorIcon className="mt-3 me-2 mb-3 editButton" />
              </div>
              <div className="d-flex align-items-start mt-2 borderBottom editDiv">
                <Checkbox className="me-2 " />
                <div className="taskSection">
                  <h3 title="Check the daily mails" alt="Check the daily mails">
                    Check the daily mails
                  </h3>
                  <h5>Set reminders for time-sensitive emails and tasks. </h5>
                  <p>09:00 AM</p>
                  <div className="subtask">
                    <div className="d-flex align-items-start mt-2">
                      <Checkbox className="me-2 " />
                      <div className="taskSection">
                        <h3
                          title="Check the daily mails"
                          alt="Check the daily mails"
                        >
                          Check the daily mails
                        </h3>
                        <h5>
                          Set reminders for time-sensitive emails and tasks.{" "}
                        </h5>
                        <p>09:00 AM</p>
                      </div>
                    </div>
                    <Button
                      variant="text"
                      sx={{ textTransform: "inherit", color: "#888" }}
                    >
                      <AddOutlinedIcon />
                      ADD SUBTASK
                    </Button>
                  </div>
                </div>
                <BorderColorIcon className="mt-3 me-2 mb-3 editButton" />
              </div>
              
              <Button  className="mb-3 mt-1" variant="contained" sx={{textTransform: 'inherit', background: '#770A97!important', width: '150px'}}><AddOutlinedIcon/> ADD TASK</Button>
            </div>
            
          </Box>
        </Box>
      </div>
    
    );
};

export default Complete;