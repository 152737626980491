import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import Feature from './Featured/Feature';
import Login from './auth/Login';
import Home from './Home/Home';
import Signup from './auth/Signup';
import ForgotPassword from './auth/ForgotPassword';
import Dashboard from './dashboard/Dashboard';
import Contact from './subpages/Contact';
import About from './subpages/About';
import Terms from './subpages/Terms';
import Privacy from './subpages/Privacy';
import HelpCenter from './subpages/HelpCenter';
import HowItWorks from './subpages/HowItWorks';
import Search from './Search';
import { useEffect, useState } from 'react';
import Admin from './dashboard/Admin/Admin';
import Yrr_wundrfly from './dashboard/Admin/Yrr_wundrfly';
import Searcholl from './dashboard/Admin/Searcholl';
import Yrr_mynewtab from './dashboard/Admin/Yrr_mynewtab';
import Mynewtab from './dashboard/Admin/Mynewtab';
import Wundrfly from './dashboard/Admin/Wundrfly';
import Ollreview from './dashboard/Admin/Ollreview';
import Vayunewtab from './dashboard/Admin/Vayunewtab';
import Searchhello from './dashboard/Admin/Searchhello';
import InviteUsers from './dashboard/Admin/InviteUsers';
import Safesearchengine from './dashboard/Admin/Safesearchengine';
import Lukall from './dashboard/Admin/Lukall';
import Speedtestgizmo from './dashboard/Admin/Speedtestgizmo';
import Safesearchengine_net from './dashboard/Admin/Safesearchengine_net';
import Safesearchengine_org from './dashboard/Admin/Safesearchengine_org';
import User_Management from './dashboard/Admin/User_Management';
import Taskticks from './dashboard/Admin/Taskticks';
import Inbox from './dashboard/Inbox';
import Today from './dashboard/Today';
import Upcoming from './dashboard/Upcoming';
import Pending from './dashboard/Pending';
import Complete from './dashboard/Complete';
import Calendar from './dashboard/Calendar';
 


function App() {
  return (
    <div className="App">
   
    <Routes> 
    <Route path="/" exact element={<Home />}/>
    <Route path="/feature" element={<Feature />}/>
    <Route path="/auth/login" element={<Login />}/>
    <Route path="/auth/signup" element={<Signup />}/>
    <Route path="/dashboard" element={<Dashboard />}/>
    <Route path="/contact-us" element={<Contact />}/>
    <Route path="/about-us" element={<About />}/>
    <Route path="/terms-of-service" element={<Terms />}/>
    <Route path="/privacy-policy" element={<Privacy />}/>
    <Route path="/help" element={<HelpCenter />}/>
    <Route path="/feature" element={<HowItWorks />}/>
    <Route path="/auth/password" element={<ForgotPassword />}/> 
    <Route path="/dashboard/settings/account" element={<Dashboard />}/>   
    <Route path="/search"  element={<Search />} />
    <Route path="/admin"  element={<Admin />} />
    <Route path="/admin/yrr_wundrfly"  element={<Yrr_wundrfly />} />
    <Route path="/admin/searcholl"  element={<Searcholl />} />
    <Route path="/admin/yrr_mynewtab"  element={<Yrr_mynewtab />} />    
    <Route path="/admin/mynewtab"  element={<Mynewtab />} />
    <Route path="/admin/wundrfly"  element={<Wundrfly />} />
    <Route path="/admin/ollreview"  element={<Ollreview />} />
    <Route path="/admin/vayunewtab"  element={<Vayunewtab />} />
    <Route path="/admin/searchhello"  element={<Searchhello />} />
    <Route path="/admin/safesearchengine"  element={<Safesearchengine />} />
    <Route path="/admin/lukall"  element={<Lukall />} />
    <Route path="/admin/speedtestgizmo"  element={<Speedtestgizmo />} />
    <Route path="/admin/safesearchengine_net"  element={<Safesearchengine_net />} />
    <Route path="/admin/safesearchengine_org"  element={<Safesearchengine_org />} />
    <Route path="/admin/Invite_Users"  element={<InviteUsers />} />
    <Route path="/admin/User_Management"  element={<User_Management />} />
    <Route path="/admin/taskticks"  element={<Taskticks />} />
    <Route path="/dashboard/inbox"  element={<Inbox />} />
    <Route path="/dashboard/today"  element={<Today />} />
    <Route path="/dashboard/upcoming"  element={<Upcoming />} />
    <Route path="/dashboard/pending"  element={<Pending />} />
    <Route path="/dashboard/complete"  element={<Complete />} />
    <Route path="/dashboard/calendar"  element={<Calendar />} />


    
    </Routes>
    
    </div>
  );
}

export default App;
