import React, { useEffect, useState, useContext } from "react";
import Header_dashboard from "./Header/Header_dashboard";
import Header_sidebar from "./Header/Header_sidebar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, Checkbox } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import axios from "axios";
import AuthContext from "../auth/Auth-context";
import ChatIcon from "@mui/icons-material/Chat";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import EventNoteIcon from "@mui/icons-material/EventNote";
import Badge from "@mui/material/Badge";
const Inbox = () => {
  const API_URL = "https://server.taskticks.com/api/users";
  const API_URL2 = "https://server.taskticks.com/api/users/comment";
  const [tasks, setTasks] = useState([]);
  const authCtx = useContext(AuthContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [UndoId, setUndoId] = useState([]);
  const [deletedTaskCount, setDeletedTaskCount] = useState(0);
  const [isChecked, setIsChecked] = useState({});
  const [commentData, setCommentData] = useState([]);

  useEffect(() => {
    const email = localStorage.getItem("email");
    fetchUserByEmail(email);
  }, [authCtx]);

  const SnackbarhandleClick = () => {
    setSnackbarOpen(true);
  };

  const SnackbarhandleClose = async (reason) => {
    console.log(reason);
    console.log(UndoId);
    if (reason === "UNDO") {
      const updatedDataUndo = {
        completed: false,
      };

      try {
        const email = localStorage.getItem("email");
        for (let i = 0; i < UndoId.length; i++) {
          setTimeout(async () => {
            let createUser = await axios.put(
              `${API_URL}/${UndoId[i]}`,
              updatedDataUndo
            );
            console.log(createUser);
            if (createUser) {
              console.log("Undo Successfully");
              await fetchUserByEmail(email);
              setSnackbarOpen(false);
            }
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    } else if (reason === "DISMISS") {
      const updatedData = {
        completed: true,
      };
      try {
        const email = localStorage.getItem("email");
        for (let i = 0; i < UndoId.length; i++) {
          setTimeout(async () => {
            let createUser = await axios.put(
              `${API_URL}/${UndoId[i]}`,
              updatedData
            );
            console.log(createUser);
            if (createUser) {
              console.log("Undo Successfully");
              await fetchUserByEmail(email);
            }
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
      setSnackbarOpen(false);
    }
    setSnackbarOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={(e) => {
          SnackbarhandleClose("UNDO");
        }}
      >
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={(e) => {
          SnackbarhandleClose("DISMISS");
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const fetchUserByEmail = async (email) => {
    try {
      const getUserByEmail = await axios.get(
        `https://server.taskticks.com/api/users/email/${email}`
      );
      //  console.log(getUserByEmail.data);
      if (getUserByEmail.data.length > 0) {
        var getData = getUserByEmail.data;
        const filtereddata = getData.filter((task) => task.completed !== true);
        setTasks(filtereddata);
        console.log(filtereddata, tasks);
        const newState = {};
        // const allcheckbox = document.querySelectorAll(".checkboxDefault input");
        for (var i = 0; i < filtereddata.length; i++) {
          newState[filtereddata[i]._id] = false; // Set each checkbox to false

          // allcheckbox[i].checked = false;
        }
        setIsChecked((prevState) => ({
          ...prevState,
          ...newState, // Merge the new state with the previous one
        }));

        // get comment function

        getComment(email);
      }

      //  console.log(tasks)
    } catch (err) {
      console.log(err);
    }
  };

  const addTaskHandler = (bool, type, subID) => {
    // setOpen(true);
    subID = subID ? subID : null;
    console.log(bool, type, subID);
    authCtx.addTask(bool, type, subID);
  };

  const editHander = (id, title, desc, duedate, priority, type, bool) => {
    console.log(id, title, desc, duedate, priority, type);
    authCtx.editTask(bool, type, id, title, desc, duedate, priority);
  };

  const completedHandlerChange = async (
    id,
    title,
    descript,
    DueDate,
    emailID,
    subID,
    originalId
  ) => {
    subID = subID ? subID : null;
    console.log(id, title, descript, DueDate, emailID, originalId);
    const checkbox = document.getElementById(id);

    if (checkbox.checked) {
      // return false;
      setIsChecked((prevState) => ({
        ...prevState,
        [id]: checkbox.checked, // Update only the checkbox that was changed
      }));
      try {
        console.log(tasks);
        const collectAllIdRef = tasks.filter((item) => id === item.subtaskId);
        console.log(collectAllIdRef);
        var completedTaskCount = 0;
        var undoId = []; // variable to store the ID of the task to be undeleteds
        if (collectAllIdRef.length > 0) {
          collectAllIdRef.map((item) => {
            //  console.log(item._id);
            completedTaskCount += 1;
            undoId = [...undoId, item._id];

            const completedData = {
              completed: true,
            };
            setTimeout(async () => {
              const updateUser = await axios.put(
                `https://server.taskticks.com/api/users/${item._id}`,
                completedData
              );
              if (updateUser) {
                checkbox.checked = false;
                console.log("Sub tasks updated successfully");
                const email = localStorage.getItem("email");

                SnackbarhandleClick();

                fetchUserByEmail(email);
              }
            }, 2000);
          });
        }

        const completedData = {
          completed: true,
        };
        const updateUser = await axios.put(
          `https://server.taskticks.com/api/users/${id}`,
          completedData
        );
        if (updateUser) {
          console.log("Completed successfully");
          const email = localStorage.getItem("email");
          fetchUserByEmail(email);
          completedTaskCount = completedTaskCount + 1;
          console.log(completedTaskCount);
          setDeletedTaskCount(completedTaskCount);
          var mainUndoId = [];
          if (undoId.length > 0) {
            mainUndoId = [...undoId, id];
          } else {
            mainUndoId = [id];
          }

          setUndoId(mainUndoId);

          console.log(UndoId);

          SnackbarhandleClick();
        }
      } catch (error) {}

      authCtx.isDeleteHandler(true);
    } else {
    }
  };

  const getComment = async (email) => {
    try {
      const response = await axios.get(`${API_URL2}/email/${email}`);
      console.log(response.data);
      setCommentData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(tasks, commentData);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={SnackbarhandleClose}
        message={deletedTaskCount + " " + "Task Completed"}
        action={action}
      />
      <Box sx={{ display: "flex" }}>
        <Header_dashboard />
        <Header_sidebar />

        <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f2edf8" }}>
          <Toolbar />
          <h5>
            <b>Inbox</b>
          </h5>
          <div className="p-3">
            <div className="row">
              <div className="col-md-10">
                <div className="d-flex">
                  <span
                    className="p-2 mt-3 me-2 mb-3"
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      fontSize: "12px",
                      lineHeight: 1,
                      borderRadius: "10px",
                    }}
                  >
                    Personal
                  </span>
                  <span
                    className="p-2 mt-3 mb-3"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: "12px",
                      lineHeight: 1,
                      borderRadius: "10px",
                    }}
                  >
                    {tasks.length} Task
                  </span>
                </div>
              </div>
              <div className="col-md-2 text-end">
                <MoreVertIcon className="mt-3 me-2 mb-3" />
              </div>
            </div>
            <Divider />
            {tasks.length > 0
              ? tasks.map((task, index) => {
                  const date = task.dueDate
                    ? new Date(task.dueDate)
                    : "No due date";
                  //  console.log(new Date(task.dueDate))
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  };
                  const humanReadableDate = date.toLocaleDateString(
                    "en-US",
                    options
                  );

                  if (!task.subtaskId) {
                    var commentCount = [];
                    for (var i = 0; i < commentData.length; i++) {
                      if (task._id === commentData[i].tasksId) {
                        commentCount = [
                          ...commentCount,
                          commentData[i].comment,
                        ];
                        console.log(
                          commentData[i].comment,
                          commentData[i].tasksId
                        );
                      }
                    }
                    console.log(commentCount.length);
                    return (
                      <div
                        key={index}
                        className="d-flex align-items-start mt-2 borderBottom editDiv"
                      >
                        <Checkbox
                          sx={{
                            color: "#946bff",
                            "&.Mui-checked": { color: "#770A97" },
                          }}
                          id={task._id}
                          className="me-2 checkboxDefault"
                          onClick={() => {
                            completedHandlerChange(
                              task._id,
                              task.taskName,
                              task.description,
                              humanReadableDate,
                              task.email,
                              task.subtaskId,
                              task.created,
                              task.start,
                              task.end,
                              task.originalId
                            );
                          }}
                          checked={isChecked[task._id]}
                        />
                        <div className="taskSection">
                          <div
                            className="taskEdit"
                            onClick={() => {
                              editHander(
                                task._id,
                                task.taskName,
                                task.description,
                                humanReadableDate,
                                task.priority,
                                "edit",
                                true
                              );
                            }}
                          >
                            <h3 alt={task.taskName}>{task.taskName}</h3>
                            <div
                              className="descriptionDiv"
                              onClick={() => {
                                editHander(
                                  task._id,
                                  task.taskName,
                                  task.description,
                                  humanReadableDate,
                                  task.priority,
                                  "edit",
                                  true
                                );
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: task.description,
                                }}
                              ></span>
                            </div>
                            <p>
                              <EventNoteIcon
                                sx={{
                                  color: "#303741",
                                  fontSize: "18px",
                                  marginRight: "5px",
                                }}
                              />{" "}
                              {humanReadableDate}{" "}
                              {commentCount.length > 0 ? (
                                <span>
                                  <ChatIcon
                                    sx={{
                                      color: "#303741",
                                      fontSize: "18px",
                                      marginLeft: "5px",
                                    }}
                                  />{" "}
                                  <span>
                                    {commentCount.length > 1
                                      ? commentCount.length + " comments"
                                      : commentCount.length + " comment"}
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="subtask">
                            {tasks.map((subtask, index) => {
                              if (subtask.subtaskId) {
                                if (
                                  subtask.subtaskId === task._id ||
                                  subtask.subtaskId === task.originalId
                                ) {
                                  console.log(
                                    subtask.subtaskId,
                                    task._id,
                                    subtask.subtaskId,
                                    task.originalId
                                  );
                                  const datesubtask = subtask.dueDate
                                    ? new Date(subtask.dueDate)
                                    : "No due date";
                                  //  console.log(new Date(subtask.dueDate))
                                  const options1 = {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  };
                                  const humanReadableDate1 =
                                    datesubtask.toLocaleDateString(
                                      "en-US",
                                      options1
                                    );
                                  var subtaskcommentCount = [];
                                  for (var i = 0; i < commentData.length; i++) {
                                    if (subtask._id === commentData[i].tasksId) {
                                      subtaskcommentCount = [
                                        ...subtaskcommentCount,
                                        commentData[i].comment,
                                      ];
                                      console.log(
                                        commentData[i].comment,
                                        commentData[i].tasksId
                                      );
                                    }
                                  }
                                  console.log(subtaskcommentCount.length);
                                  return (
                                    <div
                                      className="d-flex align-items-start mt-2"
                                      key={index}
                                    >
                                      <Checkbox
                                        sx={{
                                          color: "#946bff",
                                          "&.Mui-checked": { color: "#770A97" },
                                        }}
                                        id={subtask._id}
                                        className="me-2 "
                                        onClick={() => {
                                          completedHandlerChange(
                                            subtask._id,
                                            subtask.taskName,
                                            subtask.description,
                                            humanReadableDate,
                                            subtask.email,
                                            subtask.subtaskId,
                                            subtask.created,
                                            subtask.start,
                                            subtask.end
                                          );
                                        }}
                                        checked={isChecked[subtask._id]}
                                      />
                                      <div
                                        className="taskSection"
                                        onClick={() => {
                                          editHander(
                                            subtask._id,
                                            subtask.taskName,
                                            subtask.description,
                                            humanReadableDate1,
                                            subtask.priority,
                                            "edit",
                                            true
                                          );
                                        }}
                                      >
                                        <h3 alt={subtask.taskName}>
                                          {subtask.taskName}
                                        </h3>
                                        <h5>{subtask.description}</h5>
                                        <p>
                                          <EventNoteIcon
                                            sx={{
                                              color: "#303741",
                                              fontSize: "18px",
                                              marginRight: "5px",
                                            }}
                                          />{" "}
                                          {humanReadableDate1}{" "}
                                          {subtaskcommentCount.length > 0 ? (
                                            <span>
                                              <ChatIcon
                                                sx={{
                                                  color: "#303741",
                                                  fontSize: "18px",
                                                  marginLeft: "5px",
                                                }}
                                              />{" "}
                                              <span>
                                                {subtaskcommentCount.length > 1
                                                  ? subtaskcommentCount.length +
                                                    " comments"
                                                  : subtaskcommentCount.length +
                                                    " comment"}
                                              </span>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            })}
                            {!task.subtaskId && (
                              <Button
                                variant="text"
                                sx={{
                                  textTransform: "inherit",
                                  color: "#303741",
                                  fontWeight: 300,
                                  alignItems: "flex-start",
                                }}
                                onClick={() => {
                                  addTaskHandler(true, "addSub", task._id);
                                }}
                              >
                                <AddOutlinedIcon
                                  sx={{
                                    color: "#303741",
                                    fontWeight: 100,
                                    fontSize: "20px",
                                    marginRight: "10px",
                                  }}
                                />
                                Add sub-task
                              </Button>
                            )}
                          </div>
                        </div>
                        <BorderColorIcon className="mt-3 me-2 mb-3 editButton" />
                      </div>
                    );
                  }
                })
              : "No tasks found"}

            <Button
              onClick={() => {
                addTaskHandler(true, "add");
              }}
              className="mb-3 mt-1"
              variant="contained"
              sx={{
                textTransform: "inherit",
                background: "#770A97!important",
                width: "150px",
              }}
            >
              <AddOutlinedIcon /> Add task
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Inbox;
