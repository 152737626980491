import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
 
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
 
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import List from '@mui/material/List';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
 
import '../Dashboard.css'
import DashboardContext from '../Context/DashboardContext';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import AuthContext from '../../auth/Auth-context';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
 
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
 
  
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
     
  }),
  overflowX: 'hidden',
  
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
    
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
   
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(8)} - 1px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
    
    
    
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
      
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
 
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      
    }),
  }),
);


const Header_sidebar = () => {
  const history = useNavigate();
  const {sideBar} = useContext(DashboardContext)
  const {getIfuserIsLoggedIn} = useContext(DashboardContext)
  const authctx = useContext(AuthContext);
 const dashctx = useContext(DashboardContext)
  console.log(authctx)
  console.log(dashctx)
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [profileMenu, setProfileMenu] = useState(null);
  const profileOpen = Boolean(profileMenu);
  const [activeUrl, setActiveUrl] = useState("");

  let emailId;
  const handleDrawerOpen = () => {
    
    sideBar(true)
    setOpen(true);
 
  };

  const handleDrawerClose = () => {
    sideBar(false)
    setOpen(false);
    // console.log(`calc(${theme.spacing(8)} + 1px)`)
  };

  useEffect(() => {
    emailId = localStorage.getItem("email");
    let idToken = localStorage.getItem("idtoken");
    console.log(idToken);
    idToken = idToken ? idToken : null;
    authctx.login(idToken);
    if (idToken) {
      getUserData(idToken);
    }
  }, []);

  useEffect(() => {
        const currentUrl = window.location.href;
        var segments = currentUrl.split('/');
 
        setActiveUrl(segments[segments.length - 1]);
  }, [])

  
const getUserData = (token) => {
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=AIzaSyDJ1RhI1_rzYyM1wcb43fv9AUDeGtOlM6w",
      {
        method: "POST",
        body: JSON.stringify({
          idToken: token,
        }),
        headers: {
          "content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(res);
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            console.log(data);
            console.log(data.error.message);
            authctx.login(null);
          });
        }
      })
      .then(async (data) => {
        console.log(data);
        if (data.users) {
            getIfuserIsLoggedIn(data.users)
            
          // history("/dashboard");
        //   data.users[0].photoUrl = data.users[0].photoUrl
        //     ? setUserImg(data.users[0].photoUrl)
        //     : setUserImg("../images/dummyProfilePic.png");

          console.log(authctx);
          // history('/dashboard');

          // console.log(authctx.email);
          //   authctx.email = data.users[0].email;
          //   authctx.username = data.users[0].displayName;
          // history('/dashboard');
        }
      })
      .catch((err) => {
        alert("Session Expired. Please Login Again.");
        // console.log(err)
        authctx.login(null);
        history("/auth/login");
      });
  };


  const ProfileMenuHandleClick = (event) => {
    // alert(event.currentTarget.id);
    setProfileMenu(event.currentTarget);
  };
  const ProfileMenuHandleClose = () => {
    setProfileMenu(null);
  };

  const logoutHandler = (status) => {
    console.log(status)
    authctx.logout();
    localStorage.setItem('email', "");
    localStorage.setItem('idtoken', "");
    history('/auth/login');
  }

 


    return (
        <div>
            <Drawer className='leftsidecontainer' variant="permanent" open={open}  >
       
            <DrawerHeader sx={{padding: 0, margin: 0, justifyContent: 'start'}}>
          
          <div className=''  >
          <List className='TopList'>
          <ListItem disablePadding sx={{ display: 'block', fontWeight: 'bold!Important' }}
          id="demo-positioned-button"
          aria-controls={profileOpen ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={profileOpen ? 'true' : undefined}
          onClick={ProfileMenuHandleClick}
          >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  width: '100%',
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  fontWeight: 'bold!Important'
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {
                    dashctx.userInfo.length > 0 ? <img src={dashctx.userInfo[0].photoUrl} alt="TaskTicks" className='profilePicDash' style={{ opacity: open ? 1 : 0 }}/> : <AccountCircleOutlinedIcon className='TopiconsHeader' sx={{ opacity: open ? 1 : 0 }}/>
                  }        
                </ListItemIcon>
                <ListItemText 
    className='truncate' 
    primary={
        dashctx.userInfo.length > 0 ?  (
            <span>
                {dashctx.userInfo[0].displayName.length > 10 
                    ? dashctx.userInfo[0].displayName.slice(0, 10) + "..." 
                    : dashctx.userInfo[0].displayName}
            </span>
        ) : (
            'Login'
        )
    } 
    sx={{ opacity: open ? 1 : 0, fontWeight: 'bold !important' }} 
/> 
              </ListItemButton>
            </ListItem>
            <Menu
        anchorEl={profileMenu}
        id="account-menu"
        open={profileOpen}
        onClose={ProfileMenuHandleClose}
        onClick={ProfileMenuHandleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={ProfileMenuHandleClose}>
        <ListItemIcon>
          <ManageAccountsOutlinedIcon sx={{mr: open ? 3 : 'auto'}}/>
        </ListItemIcon> My account 
        </MenuItem>
        <Divider/>
        <MenuItem onClick={ProfileMenuHandleClose}>
        <ListItemIcon>
          <SettingsOutlinedIcon sx={{mr: open ? 3 : 'auto'}}/>
        </ListItemIcon> General Settings
        </MenuItem>
        <Divider/>
        <MenuItem onClick={ProfileMenuHandleClose}>
        <ListItemIcon>
          <MonetizationOnOutlinedIcon sx={{mr: open ? 3 : 'auto'}}/>
        </ListItemIcon> Subscription
         
        </MenuItem>
         
        
        <Divider />
        <MenuItem onClick={()=> logoutHandler(true)}>
          <ListItemIcon>
            <LogoutOutlinedIcon sx={{mr: open ? 3 : 'auto'}}/>
          </ListItemIcon>
          Logout
        </MenuItem>
        {/* <MenuItem onClick={ProfileMenuHandleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={ProfileMenuHandleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem> */}
      </Menu>
          </List>
          </div>
          {!open ? <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className='leftsideicons'
            edge="start"
            sx={{
              marginRight: 1,
              ...(open && { display: 'none' }             
              ),
            }}
          >
            {/* <MenuIcon sx={{color: '#333'}}/> */}
            
            <LastPageOutlinedIcon className='TopiconsHeader' alt='taskticks'/>
          </IconButton> : <IconButton onClick={handleDrawerClose} className='leftsideicons'>
             <FirstPageOutlinedIcon  className='TopiconsHeader' alt='taskticks'/> 
          </IconButton>
          }
          </DrawerHeader>
          <Divider />
           
      
        <List className='TopList'>
          {['dashboard', 'Inbox', 'Today', 'Upcoming', 'Pending', 'Complete', 'Calendar'].map((text, index) => {
            
            return (

           
            <ListItem key={text} disablePadding sx={{ display: 'block' }} className={activeUrl === text ? 'dashbordActiveTab' : ''}>
              <Link to={index  === 0 ? '/dashboard' : '/dashboard' + '/' + text} style={{ textDecoration: 'none' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}

              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 
                  {index  === 0 ? <img src='../images/Icons/Group 1792.png' className='Topicons' alt='taskticks Dashboard'/> : ""}
                  {index  === 1 ? <img src='../images/Icons/Group 1791.png' className='Topicons' alt='taskticks inbox'/> : ""}
                  {index  === 2 ? <img src='../images/Icons/Group 1790.png' className='Topicons' alt='taskticks Today'/> : ""}
                  {index  === 3 ? <img src='../images/Icons/Group 1789.png' className='Topicons' alt='taskticks upcoming'/> : ""}
                  {index  === 4 ? <img src='../images/Icons/Group 530.png' className='Topicons' alt='taskticks pending'/> : ""}
                  {index  === 5 ? <img src='../images/Icons/Group 1417.png' className='Topicons' alt='taskticks complete'/> : ""}
                  {index  === 6 ? <img src='../images/Icons/Group 1416.png' className='Topicons' alt='taskticks calender'/> : ""}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                
              </ListItemButton>
              </Link>
            </ListItem>
          )})}
        </List>
        <Divider />
        <List>
          {['Personal', 'Team', 'Favorites', 'Archive', 'Notepad'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index  === 0 ? <img src='../images/Icons/Group 1415.png' className='Topicons' alt='taskticks Personal'/> : ""}
                  {index  === 1 ? <img src='../images/Icons/Group 1414.png' className='Topicons' alt='taskticks team'/> : ""}
                  {index  === 2 ? <img src='../images/Icons/Vector.png' className='Topicons' alt='taskticks Favorites'/> : ""}
                  {index  === 3 ? <img src='../images/Icons/Group 1413.png' className='Topicons' alt='taskticks Archive'/> : ""}
                  {index  === 4 ? <img src='../images/Icons/Group 1412.png' className='Topicons' alt='taskticks Notepad'/> : ""}
               
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
        </div>
    );
};

export default Header_sidebar;