import React from 'react';
import SideButton from './SideButton';
import Header from './Header'

import { useContext } from 'react';

import AuthContext from '../../auth/Auth-context';

import Taskticks_data from './Taskticks_data';

function Taskticks(props) {
const authCtx = useContext(AuthContext);


  // console.log(filterTerms)
  return (
      <div>
    <Header/>
    <SideButton />
    {authCtx.email ? <Taskticks_data/> : ''}
    
    
    
          
          
      </div>
  );
}



export default Taskticks;