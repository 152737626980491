import React, { useContext, useRef } from "react";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import FlagIcon from "@mui/icons-material/Flag";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import AuthContext from "../auth/Auth-context";
import { db } from "../auth/config";
import {
  collection,
  getDocs,
  deleteDoc,
  addDoc,
  query,
  where,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import "./Dashboard.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Menu from "@mui/material/Menu";
import AddReactionOutlinedIcon from "@mui/icons-material/AddReactionOutlined";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsRoundedIcon from "@mui/icons-material/EmojiEmotionsRounded";
import axios from "axios";
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import DashboardContext from "../dashboard/Context/DashboardContext";

const AddTask = () => {
  const usercollRef = collection(db, "Users");
  const API_URL = "https://server.taskticks.com/api/users";
  const API_URL2 = "https://server.taskticks.com/api/users/comment";
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [subdescription, setSubDescription] = useState("");
  const [priority, setPriority] = useState("Priority 4");
  const [anchorEl, setAnchorEl] = useState(null);
  const [ID, setID] = useState("");
  const openCalenderView = Boolean(anchorEl);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorElP, setAnchorElP] = useState(null);
  const openPriroityView = Boolean(anchorElP);
  const [emailID, setEmailID] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEmojiPickerforComment, setshowEmojiPickerforComment] = useState(false);
  const [propDate, setpropDate] = useState();
  const [flagColor, setFlagColor] = useState();
  const audioRefsave = useRef(null);
  const [subtask, setSubtask] = useState(false);
  const [minDateForSubTask, setMinDateForSubTask] = useState();
  const [subDate, setsubDate] = useState();
  const [filteredSubtask, setfilteredSubtask] = useState();
  const [comment, setComment] = useState();
  const [commentSection, setCommentSection] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [type, setType] = useState();
  const [commentEditId, setCommentEditId] = useState();
  const [attachment, setAttachment] = useState(null);
  //console.log(authCtx);
  const dashctx = useContext(DashboardContext);


  const handleFileChange = (e) => {
    setAttachment(e.target.files[0]);
  };
  const handleEmojiSelect = (emoji) => {
    setDescription(description + emoji.emoji); // Append selected emoji to the editor content
    setSubDescription(subdescription + emoji.emoji); // Append selected emoji to the editor content
    setShowEmojiPicker(false);
  };

  const handleEmojiSelectforComment = (emoji) => {
    setComment(comment + emoji.emoji); // Append selected emoji to the editor content
    setshowEmojiPickerforComment(false);
  };
  
  const handleEmojiButton = (bool) => {
 
    setShowEmojiPicker(bool);
  };

  const handleEmojiButtonComment = (bool) => {
    setshowEmojiPickerforComment(bool);
  };
  const handlecommentSection = (bool, type, editData) => {
       if(bool){
         setComment(editData.comment);
         setCommentEditId(editData._id);
        } else {
          setCommentEditId(null);
        }
        setCommentSection(bool);
        
       
        setType(type);
        
  }

   
  const handleClose = (bool) => {
    setOpen(bool);
    setSubtask(false);
    authCtx.closePopup(bool);
    setShowEmojiPicker(false);
    setCommentEditId(null);
    setCommentSection(false);
  };

 
  useEffect(() => {
    setOpen(authCtx.ispopupOpen);

    //console.log(authCtx.typeIs);
    // if(authCtx.typeIs === 'addSub'){
    //   setSubtask(true);
    // }
    if (authCtx.typeIs === "edit") {
      editSavedTask(authCtx.editData);

    } else {
      setpropDate(null);
      setTitle("");
      setDescription("");
      setPriority("Priority 4");
      setSelectedDate(new Date());
      editSavedTask([]);
    }
  }, [authCtx.ispopupOpen, authCtx.editData, authCtx.editID, authCtx.typeIs]);

  const editSavedTask = async (editData) => {
    //console.log(editData);
    setTitle(editData.Title);
    setDescription(editData.Description);
    setpropDate(editData.DueDate);
    setSelectedDate(new Date(editData.DueDate));
    // alert(editData.priority);
    setPriority(editData.priority);
    setID(editData.id);
   // console.log(editData.id);
    getSubtask(editData.id);
    getComment(editData.id);
  };

  const getSubtask = async (editID) => {
    if (editID) {
      const q = query(
        usercollRef,
        where("type", "==", "subtask"),
        orderBy("created", "desc")
      );
      let data = await getDocs(q);
      data = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
     // console.log(data, editID);
      data = data.filter((subtask) => subtask.subtaskID === editID);
     // console.log(data);
      setfilteredSubtask(data);
    }
  };

  const updateTask = async (bool) => {
   // console.log(authCtx.editData);
    const id = authCtx.editData.id;
    // const userDoc = doc(usercollRef, id)
    // alert(id)
    const updatedUser = {
      taskName: title,
      description: description,
      dueDate: propDate,
      priority: priority,
      start: propDate,
      end: propDate,
    };
    updatedUserHandler(id, updatedUser);
    audioRefsave.current.play();
    authCtx.closePopup(bool);
    setpropDate(null);
    setTitle("");
    setDescription("");
    setPriority("");
    setSelectedDate(new Date());
  };

  const updatedUserHandler = async (id, updatedData) => {
   // console.log(updatedData);

    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedData);
    //  console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const saveTaskHandler = async (bool) => {
    const email = localStorage.getItem("email");
    const createdOn = new Date();
    // console.log(createdOn.toDateString());
    if (!title) {
      alert("Please add the Task title");
      return false;
    } else if (!description) {
      alert("Please add the Task description");
      return false;
    } else if (!propDate) {
      alert("Please set the due date");
      return false;
    } else if (!priority) {
      alert("Please set the priority");
      return false;
    }
    audioRefsave.current.play();

    var userData = {
      email: email,
      taskName: title,
      description: description,
      dueDate: propDate,
      priority: priority,
      created: createdOn,
      start: formatDate(selectedDate),
      end: formatDate(selectedDate),
    };
    try {
      const createUser = await axios.post(API_URL, userData);
     // console.log(createUser);
    } catch (error) {
      console.log(error);
    }

    sendEmail(authCtx.email, authCtx.username, title, description, propDate);
    authCtx.closePopup(bool);
    setOpen(bool);
    setpropDate(null);
    setTitle("");
    setDescription("");
    setPriority("");
    setSelectedDate(new Date());
  };

  const addTaskTitleHandler = (event) => {
    setTitle(event.target.value);
    setSubTitle(event.target.value);
  };
  const addTaskDescriptionhandler = (event) => {
    //console.log(event);

    setDescription(event.target.value);
    setSubDescription(event.target.value);
  };
  const handleChange = (event, value) => {
    setAnchorElP(event.currentTarget);
    //console.log(value);
    if (value) {
      setPriority(value);
    }
    if (value === "Priority 1") {
      setFlagColor("red");
    } else if (value === "Priority 2") {
      setFlagColor("orange");
    } else if (value === "Priority 3") {
      setFlagColor("blue");
    } else if (value === "Priority 4") {
      setFlagColor("green");
    }
  };
  // console.log(value.format("dddd, D MMMM, YYYY"));

  const calenderViewhandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCLViewClose = () => {
    setAnchorEl(null);
  };
  const handlePRViewClose = () => {
    setAnchorElP(null);
  };
  const handleAccept = (date) => {
    setpropDate(formatDate(selectedDate));
    setsubDate(formatDate(selectedDate));
    setAnchorEl(null);
    // You can perform any additional actions when the user clicks "OK"
    // setSelectedDate(date);
  };

  const handleDismiss = () => {
   // console.log("Date dismissed");
    // You can perform any additional actions when the user clicks "Cancel"
  };
  const formatDate = (inputDate) => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const date = new Date(inputDate);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const sendEmail = (email, UserName, title, description, dueDate) => {
    //console.log(email, UserName, title, dueDate);
    let datecjheck = new Date(dueDate);
    //console.log(datecjheck);
    //console.log(datecjheck.toISOString().replace(/Z$/, ""));
    //console.log(datecjheck.toLocaleString());
    let toLocaleString = datecjheck.toLocaleString();
    const inputDate = new Date(toLocaleString);
    let CheckinputDate = new Date(toLocaleString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    let TodayDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    //console.log(TodayDate === CheckinputDate, TodayDate, CheckinputDate);
    inputDate.setDate(inputDate.getDate() + 1);
    const isoString = inputDate.toISOString();
    //console.log(isoString);
    let bodyInfo =
      "<h3>Hello, " +
      UserName +
      '<br> <br> Please complete the below mentioned task before it get Overdue or if you already completed the task then ignore this notification email. <br><br><div className="parent" style="display: block; background: #fbfbfb; padding: 20px;"><div className="title">' +
      title +
      "</div><div className='description' style='font-size: 12px; font-weight: 100; margin: 10px 0;'>" +
      description +
      '</div><div style="color: #ffffff;padding: 10px;display: block; text-decoration: none; font-weight: 800; font-family: "Google Sans"; margin-top: 25px; background: rgb(10 77 165); border: 0px solid transparent; color: #fff; border-radius: 20px;"><a href="http://localhost:3000/dashboard">Open your Todolist</a></div> </div><br> Thank you</h3>';
    fetch("https://server.taskticks.com/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: UserName + " - Your Assigned task(" + title + ") - Overdue",
        html: bodyInfo,
        which: "addTask",
        DueDate: isoString,
      }),
    })
      // Its a Schedule Mail of TodoList Due task Notifications
      .then((response) => {
        if (response.status === 200) {
          console.log("Email sent successfully");
        } else {
          console.error("Error sending email");
        }
      })
      .catch((error) => {
        console.error("Network error:", error);
      });
  };

  const addSubTask = async (id, type) => {
    // alert("subtask");
    //console.log(id, type);
    const email = localStorage.getItem("email");
    const createdOn = new Date();
    // console.log(createdOn.toDateString());
    if (!subtitle) {
      alert("Please add the Task title");
      return false;
    } else if (!subdescription) {
      alert("Please add the Task description");
      return false;
    } else if (!subDate) {
      alert("Please select the due date");
      return false;
    }
    audioRefsave.current.play();
    // await addDoc(usercollRef, {
    var userData = {
      email: email,
      taskName: subtitle,
      type: type,
      description: subdescription,
      dueDate: subDate ? formatDate(subDate) : "",
      created: createdOn,
      subtaskId: id,
      start: formatDate(subDate),
      end: formatDate(subDate),
    };
    try {
      const createUser = axios.post(API_URL, userData);
      //console.log(createUser);
      setOpen(false);
      authCtx.closePopup(false);
    } catch (error) {
      console.log(error);
    }
    // });
    setSubTitle("");
    setSubDescription("");
    getSubtask(ID);
  };

  
  const addComment = async (id, bool) => {
    //console.log(comment, id);
    if (!comment) {
      alert("Please add the comment");
      return false;
    }
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const createdOn = new Date();
    var commentData = {
      email: email,
      user: username,
      comment: comment,
      created: createdOn,
      tasksId: id,
      attachment: attachment
    }
    try {
      const createComment = await axios.post(API_URL2, commentData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      //console.log(createComment);
      getComment(id);
      setComment("");
    } catch (error) {
      
    }
    // console.log(createdOn.toDateString());
  }
  const getComment = async (id) => {
    try {
      const response = await axios.get(`${API_URL2}/id/${id}`);
       console.log(response.data);
       setCommentData(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  const updateComment  = async (id) => {
    console.log(id, comment);
    const updatedData = {
      comment: comment,
      created: new Date(),
    }
    try {
      const response = await axios.put(`${API_URL2}/${id}`, updatedData);
    //  console.log(response);
    if(response.status === 200){
      getComment(authCtx.editData.id);
      setCommentSection(false);
      setCommentEditId(null);
      
    }
    setComment("");
    } catch (error) {
      console.log(error);
    }
  }
  // time coversion into human readable

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();
  
    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();
  
    const hours = date.getHours() % 12 || 12; // Convert 24-hour to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading 0 if needed
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
  
    let dayLabel;
    if (isToday) {
      dayLabel = 'Today';
    } else if (isYesterday) {
      dayLabel = 'Yesterday';
    } else {
      dayLabel = date.toLocaleDateString(); // Use locale date for anything older
    }
  
    return `${dayLabel} ${hours}:${minutes} ${ampm}`;
  }
 
  return (
    <div>
      <audio ref={audioRefsave}>
        <source src="./audio/interface-124464.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        maxWidth="md"
        fullWidth
        style={{ padding: "0px" }}
      >
        <DialogContent style={{ padding: "0px", height: "85vh" }}>
          <h3
            style={{
              fontSize: "13px",
              borderBottom: "1px solid #eee",
              padding: "15px",
              color: "#666",
              margin: 0,
            }}
          >
            {authCtx.typeIs === "edit" && "Edit task"}{" "}
            {authCtx.typeIs === "addSub" && "Add sub task"}{" "}
            {authCtx.typeIs === "add" && "Add new task"}
          </h3>
          <div className="row">
            <div className="col-md-9">
              <>
                {" "}
                <div className="addtaskOutline">
                  <TextField
                    type="text"
                    onChange={addTaskTitleHandler}
                    placeholder="Task Name"
                    className="inputForm"
                    id="addTaskTitle"
                    autoFocus
                    value={title}
                    multiline
                  />
                  <textarea
                    onChange={addTaskDescriptionhandler}
                    placeholder="Write a task description..."
                    rows="2"
                    className="textareaForm"
                    id="addTaskDescription"
                    value={description}
                  />

                  <div>
                    {showEmojiPicker === true ? (
                      <IconButton
                        sx={{ color: "#333" }}
                        onClick={() => handleEmojiButton(false)}
                      >
                        <EmojiEmotionsRoundedIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEmojiButton(true)}>
                        <AddReactionOutlinedIcon />
                      </IconButton>
                    )}

                    {showEmojiPicker && (
                      <EmojiPicker
                        style={{position: "absolute", zIndex: 999999 }}
                        onEmojiClick={handleEmojiSelect}
                      />
                    )}
                    <span>
                      <Button
                        id="basic-button"
                        aria-controls={
                          openCalenderView ? "basic-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openCalenderView ? "true" : undefined}
                        onClick={calenderViewhandleClick}
                        variant="outlined"
                        style={{
                          textTransform: "math-auto",
                          fontSize: "13px",
                          padding: "5px",
                          lineHeight: 1,
                          border: "1px solid #cccccc",
                          margin: "4px",
                          color: "#555",
                        }}
                      >
                        {propDate ? (
                          <span>
                            <InsertInvitationIcon
                              style={{
                                fontSize: "15px",
                                lineHeight: 0,
                                margin: 0,
                                padding: 0,
                                verticalAlign: "middle",
                              }}
                            />{" "}
                            {propDate}
                          </span>
                        ) : (
                          <span>
                            <InsertInvitationIcon
                              style={{
                                fontSize: "15px",
                                lineHeight: 0,
                                margin: 0,
                                padding: 0,
                                verticalAlign: "middle",
                              }}
                            />{" "}
                            Set date
                          </span>
                        )}
                      </Button>
                    </span>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openCalenderView}
                      onClose={handleCLViewClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoItem>
                          <StaticDatePicker
                            // onChange={(newValue) => {
                            //   setValue(newValue);
                            // }}
                            minDate={dayjs(new Date())}
                            onChange={setSelectedDate}
                            defaultValue={dayjs(selectedDate)}
                            onAccept={handleAccept}
                            onDismiss={handleDismiss}
                            renderInput={(params) => <Button {...params} />}
                          />
                        </DemoItem>
                      </LocalizationProvider>
                    </Menu>

                    <span>
                      <Button
                        id="composition-button"
                        aria-controls={
                          openPriroityView ? "composition-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openPriroityView ? "true" : undefined}
                        onClick={handleChange}
                        variant="outlined"
                        style={{
                          textTransform: "math-auto",
                          fontSize: "13px",
                          fontWeight: "500",
                          padding: "13px",
                          padding: "5px",
                          lineHeight: 1,
                          margin: "0px 5px",
                          border: "1px solid #ccc",
                          color: "#555",
                        }}
                      >
                        {priority ? (
                          <span>
                            <FlagIcon
                              style={{
                                fontSize: "15px",
                                lineHeight: 0,
                                margin: 0,
                                padding: 0,
                                verticalAlign: "middle",
                                marginRight: "5px",
                                color: flagColor,
                              }}
                            />
                            {priority}
                          </span>
                        ) : (
                          <span>
                            <FlagIcon
                              style={{
                                fontSize: "15px",
                                lineHeight: 0,
                                margin: 0,
                                padding: 0,
                                verticalAlign: "middle",
                                marginRight: "5px",
                              }}
                            />{" "}
                            set priority
                          </span>
                        )}
                      </Button>

                      <Menu
                        id="composition-menu"
                        anchorEl={anchorElP}
                        open={openPriroityView}
                        onClose={handlePRViewClose}
                      >
                        <MenuItem
                          onClick={() => handleChange("select", "Priority 1")}
                          className="iconSize"
                        >
                          <FlagIcon
                            style={{ color: "red", marginRight: "5px" }}
                          />{" "}
                          Priority 1
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleChange("select", "Priority 2")}
                          className="iconSize"
                        >
                          <FlagIcon
                            style={{ color: "orange", marginRight: "5px" }}
                          />{" "}
                          Priority 2
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleChange("select", "Priority 3")}
                          className="iconSize"
                        >
                          <FlagIcon
                            style={{ color: "blue", marginRight: "5px" }}
                          />{" "}
                          Priority 3
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleChange("select", "Priority 4")}
                          className="iconSize"
                        >
                          <FlagIcon
                            style={{ color: "green", marginRight: "5px" }}
                          />{" "}
                          Priority 4
                        </MenuItem>
                      </Menu>
                    </span>
                  </div>
                </div>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose(false);
                    }}
                    className="editAddButtonCancel"
                  >
                    Cancel
                  </Button>
                  {authCtx.typeIs === "addSub" ? (
                    <Button
                      className="editAddButton"
                      onClick={() => addSubTask(authCtx.subID, "subtask")}
                    >
                      Add sub task
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        authCtx.typeIs === "edit"
                          ? updateTask(false)
                          : saveTaskHandler(false);
                      }}
                      className="editAddButton"
                    >
                      {authCtx.typeIs === "edit" ? "Save" : "Add Task"}
                    </Button>
                  )}
                </DialogActions>
              </>
               

               {
                commentData ? <>
                { commentData.length > 0 ?
                  commentData.map((comment, index) => {
                  
                     var flagBgColor = "#fff";
                     var boxShadow = "hsl(200, 0%, 100%) 0 2px 0 inset, hsla(215, 15%, 92%, 0.3) 0 -2px 0 inset, hsla(215, 15%, 89%, 0.5) 0 1px 2px 0";
                    if(commentEditId === comment._id){
                      boxShadow = "0px 0px 0px #CFB1EA";
                      flagBgColor = "#cfb1ea33";
                    } else {
                      flagBgColor = "#fff";
                      boxShadow = "hsl(200, 0%, 100%) 0 2px 0 inset, hsla(215, 15%, 92%, 0.3) 0 -2px 0 inset, hsla(215, 15%, 89%, 0.5) 0 1px 2px 0";
                    }
                    return (
                      <div
                className="profileWithcmtbtn d-flex m-0 pt-1 pb-1"
                style={{ textAlign: "left", padding: "20px" }}
                key={index}
              >
                <img
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                  src={
                    dashctx.userInfo[0]
                      ? dashctx.userInfo[0].photoUrl
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                /> 
                <Button
                  sx={{
                    textTransform: "inherit",
                    color: "#000",
                    fontWeight: "500",
                    width: "91%",
                    textAlign: "left!important",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                    backgroundColor:  flagBgColor,
                    display: "inline-block",
                    border: "1px solid #e8eaee",
                    boxShadow: boxShadow 
                  }}
                  // className="cmtbtn btn"
                  className="cmtbtn btn"
                  onClick={() => {handlecommentSection(true, "edit", comment)}}
                > <div className="commentPreview"><span className="commentName"> {comment.user} </span>. <span className="commentDate">{formatTimestamp(comment.created)}</span></div>
                  <div className="commentText">{comment.comment}</div>
                  <div className="commentImg"><img src={"https://server.taskticks.com/uploads/"+comment.attachment} alt="img" /></div>
                </Button>
              </div>
                    )
                }) : ''
              }
                </> : ''
               }
               

              {!commentSection ? <div
                className="profileWithcmtbtn"
                style={{ textAlign: "left", padding: "20px" }}
              >
                <img
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                  src={
                    dashctx.userInfo[0]
                      ? dashctx.userInfo[0].photoUrl
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                  }
                />
                <Button
                  sx={{
                    textTransform: "inherit",
                    color: "#333",
                    fontWeight: "200",
                    width: "91%",
                    textAlign: "left!important",
                    justifyContent: "flex-start",
                    marginLeft: "10px",
                    backgroundColor: "#1976d20a",
                  }}
                  className="cmtbtn btn"
                  onClick={() => {handlecommentSection(true, 'add', '')}}
                >
                  Comment
                  
                </Button>
              </div>: ''}
              {commentSection ? <div className="commentSectionparent pt-2 pb-2">
                <TextField
                  className="inputForm"
                  sx={{ width: "100%", border: "0px solid #eee" }}
                  placeholder="Comment"
                  multiline
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  autoFocus
                />
                <input type="file" onChange={handleFileChange} />
                <div className="row">
                  <div className="col-md-4">
                    {showEmojiPickerforComment === true ? (
                      <IconButton
                        sx={{ color: "#333" }}
                        onClick={() => handleEmojiButtonComment(false)}
                      >
                        <EmojiEmotionsRoundedIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleEmojiButtonComment(true)}
                      >
                        <AddReactionOutlinedIcon />
                      </IconButton>
                    )}

                    {showEmojiPickerforComment && (
                      <EmojiPicker
                        style={{ position: "absolute" }}
                        onEmojiClick={handleEmojiSelectforComment}
                      />
                    )}
                  </div>
                  <div className="col-md-8 text-end m-0 p-0">
                    <Button onClick={() => {handlecommentSection(false)}} className="editAddButtonCancel me-2">Cancel</Button>
                    {type === "add" ? (
                      <Button onClick={() => addComment(authCtx.editData.id, true)} className="editAddButton">Comment</Button>
                    ) : <Button onClick={() => updateComment(commentEditId)} className="editAddButton">update comment</Button>}
                  </div>
                </div>
              </div>: ''}
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12 borderleft"></div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddTask;
